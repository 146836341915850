.matches-container {
    width: 50%;
}

.matches-container-outerrow {
    width: 100%;
    margin: 2em 0;
}

.matches-text-subheader {
    width: 100%;
    margin-top: 3em;
    margin-block-end: .5em;
}

.matches-text-fullwidth {
    width: 100%;
}

.matches-text-date {
    width: 100%;
    margin-block-end: .5em;
}

.matches-text-teeTime {
    width: 100%;
    margin-block-start: .5em;
    margin-block-end: 0;
    color: #606060
}

.matches-text-course {
    width: 100%;
    margin-block-start: .25em;
    margin-block-end: 0;
    color: #606060
}

.matches-container-innerrow {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

.matches-column {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
    .matches-container {
        width: 100%;
    }
}