.standings-container {
    margin-top: 3em;
    width: 30%;
}

.standings-list {
    background-color: white;
    border: 1px #CCCCCC;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.standings-list-name {
    padding-left: .75em !important;
}

@media only screen and (max-width: 600px) {
    .standings-container {
        width: 100%;
    }
    .standings-list {
        margin: 0 2em !important;
    }
}