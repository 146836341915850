.container {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 2em 0;
  flex-wrap: wrap;
}

.header-container {
  width: 100%;
}

.header-link-container {
  display: flex;
  justify-content: center;
}

.header-logo {
  width: 10em;
  height: 10em;
}

.header-nav-link {
  margin: 1em .5em;
  font-size: 2em;
  font-weight: bold;
  text-decoration: none;
}

.header-nav-link:focus,.header-nav-link:visited,.header-nav-link:link,.header-nav-link:active,.header-nav-link:hover {
  text-decoration: none;
  color: black;
}

.header-nav-link-active {
  color: #00a5db !important;
}